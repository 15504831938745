/* eslint-disable no-prototype-builtins */
import { useEffect } from 'react';
import { useActiveSettingPreviewStore } from 'store/ActiveSettingPreviewStore';
import { useGameCenterSettingStore } from 'store/GameCenterSettingStore';

const GAME_CENTER_REFRESH_KEY = 'GAME_CENTER_REFRESH';

/* 
  This event listener listen selectedPreview event from studio, and update in from useActiveSettingPreviewStore.
  This event listener listen event if event.data has selectedPreview property
 */
export const useSettingPreviewListener = () => {
  const { setSelectedPreview } = useActiveSettingPreviewStore();
  const { setGameCenter } = useGameCenterSettingStore();

  const handleWindowEventsFromStudio = (event: MessageEvent): void => {
    if (event.origin !== process.env.REACT_APP_STUDIO_HOST) return;

    if (event.data.type === GAME_CENTER_REFRESH_KEY) {
      setGameCenter(event.data.gameCenter);
    } else if (event?.data?.hasOwnProperty('selectedPreview')) {
      const receivedData = event.data?.selectedPreview || null;
      setSelectedPreview(receivedData);
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleWindowEventsFromStudio);
    return () => window.removeEventListener('message', handleWindowEventsFromStudio);
  }, [setSelectedPreview]);
};
