import {
  IGameCenter,
  IGameCenterDetail,
  IGameCenterEmail,
  IGameCenterPopUp,
  IGameCenterRewardSettings,
  IGameCenterUserBase,
  IModuleLock,
} from '@flarie/common';
import { IModuleType } from 'interfaces/ModuleType';
import { create } from 'zustand';

export interface IGameCenterSetting {
  detail: IGameCenterDetail | null;
  email: IGameCenterEmail | null;
  rewardSettings: IGameCenterRewardSettings | null;
  userBase: IGameCenterUserBase | null;
  popup: IGameCenterPopUp | null;
  modules: Array<IModuleType>;
  moduleLock: IModuleLock;
}

interface Actions {
  setGameCenter: (data: IGameCenter) => void;
  updateDetail: (detail: IGameCenterDetail) => void;
  updatePopup: (popup: IGameCenterPopUp) => void;
  updateRewardSettings: (rewardSettings: IGameCenterRewardSettings) => void;
  updateEmail: (email: IGameCenterEmail) => void;
  updateUserBase: (email: IGameCenterUserBase) => void;
  setModules: (modules: Array<IModuleType>) => void;
  updateModuleLock: (moduleLock: IModuleLock) => void;
}

export const useGameCenterSettingStore = create<IGameCenterSetting & Actions>((set) => ({
  detail: null,
  email: null,
  rewardSettings: null,
  userBase: null,
  popup: null,
  modules: [],

  moduleLock: {
    games: [],
    rewards: [],
  },

  setGameCenter: (gameCenter: IGameCenter) => set({ ...gameCenter }),

  updateDetail: (detail: IGameCenterDetail) => set({ detail }),
  updatePopup: (popup: IGameCenterPopUp) => set({ popup }),
  updateRewardSettings: (rewardSettings: IGameCenterRewardSettings) => set({ rewardSettings }),
  updateEmail: (email: IGameCenterEmail) => set({ email }),
  updateUserBase: (userBase: IGameCenterUserBase) => set({ userBase }),
  updateModuleLock: (moduleLock: IModuleLock) => set({ moduleLock }),
  setModules: (modules: Array<IModuleType>) => set({ modules }),
}));
